import React, { useEffect, useState } from 'react'
import { css } from "@emotion/react"
import styled from '@emotion/styled'
import Image from './Image'
import { mobile } from "~styles/global"
import useBreakpoint from "~utils/useBreakpoint"

const Value = ({ className, value, onUpdate, currentlyActiveValue, price }) => {

  const { isMobile } = useBreakpoint()

  const updateActive = ()=> {
    if(onUpdate){
      if(currentlyActiveValue === value.title){
        onUpdate(null)
      } else (
        onUpdate(value.title)
      )
    }
  }

  const setActive = ()=> {
    if(onUpdate){
      onUpdate(value.title)
    }
  }
  const unsetActive =()=> {
    if(onUpdate){
      onUpdate(undefined)
    }
  }

  return(
    <ValueTile
      className={className}
      onClick={isMobile ? ()=> updateActive() : null}
      onMouseEnter={!isMobile ? ()=> setActive() : null}
      onMouseLeave={!isMobile ? ()=> unsetActive() : null}
      css={css`pointer-events: ${price ? `none`: `all`};`}
    >
      <div>
        {price &&
        <RoundContainer>
          <span className="caps">{Array(value).fill(`$`)}</span>
        </RoundContainer>
        }
        <RoundImage 
          image={value.icon} 
          aspectRatio={1} 
          key={value.icon?.asset?._id} 
          css={css`
        `}/>
        {!price &&
        <Shadow css={css`
          display: block;
          transform: ${(currentlyActiveValue === value.title) ? `translate(2px, 2px)` : `translate(0px, 0px)`};
        `}/>
        }
      </div>
      <span 
        className="highlight-s" 
        css={css`
          display: block;
          opacity: ${(currentlyActiveValue === value.title) ? `1` : `0`};
          transition: opacity 0.25s;
      `}>
        {value.title}
      </span>
    </ValueTile>
  )
}

const ValueTile = styled.button`
  position: relative;
  > div{
    width: 40px;
    height: 40px;
    position: relative;
    ${mobile}{
      width: 26px;
      height: 26px;
    }
  }
  > span {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    ${mobile}{
      bottom: -22px;
    }
  }
`
const RoundContainer = styled.div`
  border: 1px solid var(--black);
  background: var(--white);
  border-radius: 50%;
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  align-items: center;
  .caps{
    letter-spacing: 0em;
  }
`

const RoundImage = styled(Image)`
  border: 1px solid var(--black);
  background: var(--white);
  border-radius: 50%;
  overflow: hidden;
  padding: 3px;
`

const Shadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--black);
  border-radius: 50%;
  border: 1px solid var(--black);
  z-index: -1;
  transition: transform 0.25s;
`
export default Value
