import React, { useEffect, useLayoutEffect } from 'react'
import { css } from "@emotion/react"
import * as Flickity from 'react-flickity-component'
import "~styles/flickity.css";
import Section from './Section'
import { mobile } from "~styles/global"
import normalizeWheel from 'normalize-wheel';

const MarqueeSlider = ({className, children}) => {

  let requestId
  let n = 0
  let flkty = null

  const play = () => {
    // Set the decrement of position x
    window.cancelAnimationFrame(requestId)
    if (flkty) {
      flkty.x = flkty.x - 0.5
      // Settle position into the slider
      flkty.settle(flkty.x)
      // Set the requestId to the local variable
      requestId = window.requestAnimationFrame(play)
    }
  }

  const easeOut = () => {
    if(requestId) {
      // Cancel the animation
      window.cancelAnimationFrame(requestId)
      n++
      flkty.x = flkty.x - (1 - (n / 50)) * 0.5
      flkty.settle(flkty.x)
      // Reset the requestId for the next animation.
      requestId = window.requestAnimationFrame(easeOut)
      if(n > 50){
        pause()
      }
    }
  }

  const pause = () => {
    if(requestId) {
      // Cancel the animation
      window.cancelAnimationFrame(requestId)
      // Reset the requestId for the next animation.
      requestId = undefined;
      n = 0
    }
  }
  
  useEffect(()=> {
    play()
  }, [])


  // useLayoutEffect(() => {
  
  //   let range = {
  //     value: 0,
  //     max: 180,
  //     min: -180,
  //     step: 1,
  //     increase: function(number) {
  //       const threshold = this.max / flkty.slides.length;
  //       if(this.value < this.max) {
  //         this.value += this.step;
  //       }
  //       if(this.value >= threshold) {
  //         flkty.next();
  //         this.value -= threshold;
  //       }
  //     },
  //     decrease: function(number) {
  //       const threshold = this.max / flkty.slides.length;

  //       if(this.value > this.min) {
  //         this.value -= this.step;
  //       }
  //       if(this.value <= threshold) {
  //         flkty.previous();
  //         this.value += threshold;
  //       }
  //     }
  //   };
  //   flkty.element.addEventListener('wheel', event => {
  //     // const wheelNormalized = normalizeWheel(event);
  //     // const direction = wheelNormalized.spinX * 100;
  //     // direction > 0 ? range.increase(direction) : range.decrease(direction);
  //     // flkty.startAnimation();
  //     // console.log(wheelNormalized.pixelX, wheelNormalized.pixelY);
  //     const wheelNormalized = normalizeWheel(event);

  //     if(wheelNormalized.pixelY === -0){
  //       flkty.applyForce(-wheelNormalized.pixelX / 4);
  //       flkty.startAnimation();
  //       flkty.dragEnd();
  //     }

  //   })
  // }, [flkty])

  const flickityOptions = {
    prevNextButtons: false,
    pageDots: false,
    adaptiveHeight: false,
    wrapAround: true,
    cellAlign: 'left',
    freeScroll: true,
    contain: true,
    cellAlign: 'center',
  }

  return(
    <Section 
      className={className}
    >
      <div 
        onMouseLeave={()=> play()}
        onMouseEnter={()=> easeOut()}
        css={css`grid-column: span 12;`}
      >
        <Flickity
          flickityRef={c => flkty = c}
          className={'carousel'} // default ''
          elementType={'div'} // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          // static // default false
          css={sliderCss}
        >
          {children}
          {children}
        </Flickity>
      </div>
    </Section>
  )
}


const sliderCss = css`
  grid-column: span 12;
  margin-right: -40px;
  margin-left: -40px;
  ${mobile}{
    margin-right: -15px;
    margin-left: -15px;
  }
`
export default MarqueeSlider
